import axios from "axios";

export const headers = {
	"Tenant-Access-Token": "MTc2MGExMjY1MWQ3MjJhMmU4MjM5NjhlMjI3MTI5Mjg=",
	"Tenant-Access-Key": "fvi7FY1EsJyRTg",
};
export const BASE_URL = "https://immersive-game-india.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});


